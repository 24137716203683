.submitCTA {
  width: 100%;
  margin: var(--cui-spacings-giga) 0 var(--cui-spacings-mega) 0;
  user-select: none;
}

@media (min-width: 768px) {
  .submitCTA {
    user-select: auto;
  }
}

.formContainer {
  width: 100%;
}

.notification {
  margin-top: var(--cui-spacings-mega);
  margin-bottom: var(--cui-spacings-giga);
}

.bottomSpacingInput {
  margin-bottom: var(--cui-spacings-mega);
}

.defaultInput {
  margin-bottom: var(--cui-spacings-kilo);
}

.inviteContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: var(--cui-spacings-mega) 0;
}

.inviteLink {
  margin: 0 var(--cui-spacings-kilo);
}

.termsCheckbox {
  margin-top: var(--cui-spacings-mega);
  margin-bottom: var(--cui-spacings-mega);
}

.CTAContainer {
  display: flex;
  justify-content: flex-end;
}

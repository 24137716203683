.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.headline {
  width: 100%;
  margin-bottom: 24px;
  text-align: left;
}

.body {
  width: 100%;
  margin-bottom: var(--cui-spacings-mega);
  text-align: left;
}

.verificationBox {
  display: flex;
  flex-direction: column;
  gap: 44px;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.authMethodIcon {
  margin-bottom: var(--cui-spacings-mega);
}

@media (min-width: 480px) {
  .authMethodIcon {
    margin-bottom: var(--cui-spacings-giga);
  }
}

.emailVerificationBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.emailVerificationHeadline {
  margin-bottom: var(--cui-spacings-mega);
}

.bodyMargin {
  margin-bottom: var(--cui-spacings-giga);
}

.footerSpacer {
  margin-bottom: var(--cui-spacings-exa);
}
